//---------------------------------------------
//
//  Table of Contents
//
//    1. Basics
//    2. Page Layout
//    3. Components
//
//---------------------------------------------

//---------------------------------------------
// 1. Basics
//---------------------------------------------

@import "variables";
@import "bootstrap";
@import "fonts";

// If you do not want to load the customized bootstrap version, just use the following line.
// @import "~bootstrap/scss/bootstrap";

$color-bg: #ffffff;
$color-txt: #333333;
$color-orange: #c9743f;

//---------------------------------------------
// 2. Page Layout
//---------------------------------------------

body {
  font-family: "Slabo 13px", serif;
  font-weight: 400;
  font-style: normal;
  line-height: 2rem;
  color: $color-txt;


  .container {
    max-width: 768px;
  }

  a {
    color: $color-txt;
    text-decoration: none;
    transition: color 0.3s ease-out;
    &:hover {
      color: $color-orange;
    }
  }

  .header {
    margin: 40px 0;

    h1 {
      margin: 0;
      font-family: "contempora-script", sans-serif;
      font-weight: 400;
      font-style: normal;
    }
    h2 {
      margin: 0 0 0 170px;
      font-size: 1rem;
      color: $color-orange;
      text-transform: lowercase;
    }
  }

  .contact {
    p {
      letter-spacing: 2px;

      strong {
        letter-spacing: 6px;
      }
    }

  }

  main {
    p {
      text-align: justify;
      &:after {
        content: "";
        display: block;
        width: 12px;
        height: 12px;
        background-color: $color-orange;
        border-radius: 6px;
        margin: 30px auto;
      }
    }
  }



}

//---------------------------------------------
// 3.Components
//---------------------------------------------

@import "components/slider";
